import cx from 'classnames';
import { Link, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Category from '../components/Category/Category';
import PageFooter from '../components/pageFooter/pageFooter';
import SEO from '../components/seo';
import { usePageContext } from '../contexts/pageContext';
import { useMdDataCV } from '../static-queries/useMdCvData';
import blogTemplateStyles from '../templates/cv.module.scss';
import styles from './index.module.scss';

const CvPage: React.FC<PageProps<null>> = ({data}) => {
  const randNum = () => Math.floor(Math.random() * 256);
  const [color, setColor] = useState(
    `rgb(${randNum()},${randNum()},${randNum()})`
  );

  const [isPrinting, setIsPrinting] = useState(false);

  const changeColor = () => {
    setColor(`rgb(${randNum()},${randNum()},${randNum()})`);
  };

  const {setIsSideBarShown, setPageType, pageType} = usePageContext();

  useEffect(() => {
    const colorTimer = setInterval(changeColor, 125);
    setIsSideBarShown(false);
    return () => {
      clearInterval(colorTimer);
    };
  }, []);
  // const categories = data.categories.group

  const renderContent = (content: string) => {
    // This regex captures the content between <!-- cv-start --> and <!-- cv-end --> tags
    const regex = /<!-- cv-start -->([\s\S]*?)<!-- cv-end -->/gm;
    const matches = regex.exec(content);

    // Return only the content between the cv tags if it exists, otherwise an empty string
    return matches ? matches[1] : '';
  };

  const cvData = useMdDataCV();
  return (
    <div className={'min-h-screen relative'}>
      {pageType === 'pdf' && !isPrinting && (
        <p
          onClick={() => {
            setIsPrinting(true);
            setTimeout(() => {
              window.print();
            },500)
            setTimeout(() => {
              setIsPrinting(false);
            },1000)
          }}
          className="cursor-pointer fixed bottom-0 right-10 p-5 text-xs">
          Print
        </p>
      )}
      {!isPrinting && <p
        onClick={() =>
          pageType === 'default' ? setPageType('pdf') : setPageType('default')
        }
        className="cursor-pointer fixed bottom-0 right-0 p-5 text-xs">
        {pageType === 'default' ? 'PDF Mode' : 'Web Mode'}
      </p>}
      <SEO title="CV | Gordo" />

      <section
        className={cx(
          'h-full md:flex md:items-center sm:p-3 block py-6 md:justify-center min-h-screen md:mt-0 overflow-y-scroll z-50 w-full',
          styles.mainContainerCv
        )}>
        <div className={'px-6 max-w-2xl pt-32'}>
          {pageType === 'pdf' && <div className="w-full font-writing text-gray-500 mx-auto flex items-center flex-col justify-center mb-20">
            <p>Gordo</p>
            <p>cv@gordo.design</p>
          </div>}
          <div className="flex justify-center text-4xl text-center md:text-4xl mb-6 font-gordo text-gray-800 tracking-wider">
            {/* <img width={150} src="/images/unicorn-face.png"></img> */}
            <img width={100} src="/images/unicorn2.gif"></img>
          </div>
          <h1 className="text-3xl text-center md:text-4xl mb-18 font-gordo text-gray-800 tracking-wider">
            CV
          </h1>
          <section className="my-10 text-gray-700 font-writing">
            <h2 className="mb-6 font-charisma leading-9">Hi there! 👋</h2>
            <h2 className="mb-6 font-charisma leading-9">
              I'm a creative and tech-oriented mind, focused in the practical
              applications of web3. Former architect turn in to tech 14 years
              ago.
            </h2>
            <h3 className="mb-10 font-charisma leading-6 tracking-wider">
              I have experience building & driving the whole tech product stack
              around Governance, digital arts, and digital identity.
            </h3>
            <p className="mb-10 leading-6">
              +14 years working in tech startups, specialized in the user side
              of the build up scale. Concept, Product, Frontend. <br />
              +5 years working in full spec web3 products, from wallets to full
              set protocols. Building in self sovereing digital identity, NFT
              experiences, DAOs and governance implementations. etc
            </p>
            <p className="mb-2 leading-6">The other side of me:</p>
            <p className="mb-10 leading-6">
              {
                "☕️ - I'm a fanatic of good coffee. (Seriously, I have a monthly coffee buying group with +40 people)"
              }
              <br />

              {
                "💿 - I love music in all its forms. I DJ and produce electronic music for fun, and I'm deeply rooted in the underground electronic music scene in Europe. I used to produce & promote small underground parties, and help a couple small festivals (5k-10k people) with stage scenography and general production."
              }
              <br />

              {
                "🎨 - I'm also deeply involved in the Arts Ecosystem in Europe, spanning across Lisbon, Madrid, Barcelona, and Berlin."
              }
              <br />
            </p>
            <h3 className="mb-2 leading-6 font-bold">
              Profiles in which I shine:
            </h3>
            <p className="mb-10 leading-6">
              Product development is my specialty, encompassing roles such as
              <b>
                {' '}
                Product Engineer, Design Engineer, Product Designer, and Product
                Manager
              </b>
              . I am adept at both working within and leading these roles.{' '}
              <br /> For me, the significance of a project comes first; fitting
              into these roles follows naturally. <br />
              <br />
              +14 years working in tech startups,specializing in the user-facing
              aspects of scaling up. My areas of expertise include Product
              Development from zero to market, Product Design, and Frontend
              Engineering. <br />
              <br />
              +5 years working on comprehensive web3 products, ranging from
              wallets to complete protocol sets. I've worked in SSI (Self
              Sovereing Identity) solutions, NFT experiences, DAOs, and complex
              governance protocol implementations, among others.
            </p>
            <h3 className="mb-2 leading-6 font-bold">Intro:</h3>
            <p className="mb-2 leading-6">
              Possessing a robust creative and process management oriented
              background as a former architect and over 15 years of self-taught
              technical expertise building, designing and working in early stage
              tech start-ups, I code in several languages I understand the
              business, product and technical language to engage in deep
              technical, design, product or business conversation. With more
              than 14 years of experience in tech products, I have built,
              designed, and led products in various areas of the field. I'm used
              to wear many hats. I enjoy solving problems.
            </p>
            <p className="mb-2 leading-6">
              I thrive at the intersection of arts and tech, creating
              exceptional user experiences, and simplifying complex technologies
              and procedures into making them easy and appealing. Knowing what
              can be done technically in all the steps of the building process
              and what will the user will understand and perceive. I'm used to
              take decisions based on available manpower to time deadlines in
              uncertain timelines and product roadmaps.
            </p>
            <p className="mb-2 v">
              I have collaborated with early and mid-sized startups over the
              last 15 years, focusing on complex UX products to make them
              user-friendly and comprehensible.
            </p>
            <p className="mb-2 leading-6">
              In the early days of web3, before it was even known as such, I
              became both a builder and advocate for its future, as well as for
              the profound societal changes this technology promises. Enhancing
              user experience is the next frontier in this ecosystem, and I am
              focused on developing the technologies that make these
              advancements possible while preserving sovereignty throughout the
              process.
            </p>
            <p className="mb-2 leading-6">
              I've worked with products in Web3 Identity, Arts, DAOs and with
              cutting-edge protyted governance systems.
            </p>

            <hr className="my-10 text-blac leading-6"></hr>

            <h3 className="mb-2 leading-6 font-bold">Some projects:</h3>
          </section>

          {cvData.allMarkdownRemark.edges.map((edge: any) => (
            <div className="mb-10" key={edge.node.id}>
              <p className="font-charisma text-sm text-gray-600 mb-1 tracking-wide">
                {edge.node.frontmatter.dateStart} -{' '}
                {edge.node.frontmatter.dateEnd
                  ? edge.node.frontmatter.dateEnd
                  : 'Ongoing'}
              </p>
              <div className="flex gap-2 items-center">
                <h2 className="font-charisma text-gray-800 tracking-wide">
                  {edge.node.frontmatter.title}
                </h2>
                {pageType === 'default' && (
                  <Link
                    className={cx(
                      'h-3 w-3 md:block transition-all hover:scale-125'
                    )}
                    to={`/${edge.node.fields.type}/${edge.node.fields.slug}`}>
                    <img src="/images/arrow-right.svg" />
                  </Link>
                )}
              </div>
              {/* <p>{edge.node.excerpt}</p> */}
              <div
                className={cx(
                  blogTemplateStyles.blog__body,
                  'md:pr-10 md:pt-6 pt-4 pb-14 w-full'
                )}
                dangerouslySetInnerHTML={{
                  __html: renderContent(edge.node.html),
                }}
              />
              {pageType === 'default' && (
                <div className="hidden md:flex mt-10 flex-wrap">
                  {edge.node.frontmatter.categories?.map((cat: string) => {
                    return (
                      <div>
                        <Category
                          tooltip
                          key={cat}
                          text
                          boring
                          category={cat}
                          isClickable={true}
                          size={10}
                        />
                        {/* <Category isClickable={false} key={cat} size={8} category={cat}/> */}
                      </div>
                    );
                  })}
                </div>
              )}
              <hr className="my-10 leading-6"></hr>
            </div>
          ))}
          {pageType === 'pdf' && <div className="w-full font-writing text-gray-500 mx-auto flex items-center flex-col justify-center pb-4">
            <h3>Curriculum Vitae</h3>
            <p>Gordo</p>
            <p>cv@gordo.design</p>
            <p className='mt-16'>Thanks for reading, please don't hesitate to email me if you have any question.</p>
          </div>}
        </div>
      </section>
      {pageType === 'default' && <PageFooter />}
    </div>
  );
};

export default CvPage;
