import { graphql, useStaticQuery } from 'gatsby';

export function useMdDataCV() {
    const data = useStaticQuery<GatsbyTypes.getCVDataQuery>(graphql`
        query getCVData {
            allMarkdownRemark(
                sort: { fields: [frontmatter___date], order: DESC },
                filter: { fields: { place: { in: ["cv"] } } }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            date(formatString: "MMMM Do, YYYY")
                            dateStart(formatString: "MMMM, YYYY")
                            dateEnd(formatString: "MMMM, YYYY")
                            categories
                            hero_image {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        fields {
                            slug
                            type
                        }
                        excerpt(pruneLength: 200)
                        html
                    }
                }
            }
        }
    `);
    return data;
}
